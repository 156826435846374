<template>
  <section>
    <button
      @click.prevent="toggleModal"
      class="btn btn--print-upload px-5 py-3 mr-3"
    >
      {{ $t("pending_items") }}
    </button>

    <!-- modal start -->
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="800px"
      class="overflow-hidden h-600px"
    >
      <v-card>
        <v-card-title class="custom-card-title">
          <h3 class="mb-0 font-weight-bolder">{{ $t("pending_items") }}</h3>
        </v-card-title>
        <v-card-text>
          <v-card-text>
            <div class="scanned-block">
              <v-data-table
                :headers="headers"
                :items="serverData.pending_items"
                class="striped-table"
                item-value="id"
              >
                <template v-slot:item="{ item, index, select, isSelected }">
                  <tr :class="index % 2 === 0 ? 'even-row' : 'odd-row'">
                    <td v-for="header in headers" :key="header.value">
                      {{ item[header.value] }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-row justify="center" class="align-items-end">
            <v-col class="d-flex justify-center">
              <button
                @click.prevent="toggleModal"
                type="submit"
                class="btn btn--export-filter px-5 py-3"
              >
                {{ this.$t("close") }}
              </button>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <!-- modal end -->
    </v-dialog>
  </section>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "PendingItems",
  props: ["id"],
  data: () => ({
    dialog: false,
    serverData: null,
    headers: [
      // { text: "Id", value: "id" },
      { text: "From location", value: "from_location" },
      { text: "Package code", value: "from_package_code" },
      // { text: "Line no.", value: "sku_line_no" },
      { text: "SKU", value: "sku_barcode" },
      { text: "Description", value: "description" },
      { text: "Damaged", value: "is_damaged" },
      // { text: "UOM", value: "uom" },
      { text: "Allocated qty", value: "allocated_qty" },
      { text: "Pending qty", value: "pending_qty" },
      { text: "Batch no.", value: "batch_number" },
      { text: "Serial no.", value: "serial_no" },
      { text: "Vin no.", value: "vin_no" },
    ],
  }),
  methods: {
    async toggleModal() {
      if (!this.dialog) {
        await this.loadServerData();
      }
      this.dialog = !this.dialog;
    },
    async loadServerData() {
      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        warehouse_id: this.selectedWarehouse,
        id: this.id,
      };

      try {
        const response = await ApiService.post(
          `warehouse_management/custom/standard_order_picking/pending-items`,
          payload
        );
        const { data } = response;
        this.serverData = data;
      } finally {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
  },
  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
};
</script>

<style scoped>
.striped-table {
  width: 100%;
  border-collapse: collapse;
}

.striped-table tr {
  border-bottom: 1px solid #e0e0e0;
}

.striped-table .even-row {
  background-color: #f9f9f9;
}

.striped-table .odd-row {
  background-color: #ffffff;
}
.scanned-block {
  height: calc(90vh - 462px);
  overflow: auto;
}
</style>
